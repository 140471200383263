<template>
  <div>
    <p class="msg">
      <van-icon
        name="arrow-left"
        size="0.34rem"
        style="margin-left: 0.2rem"
        @click="$router.go(-1)"
      /><span class="rigth" @click="go">确定</span>
    </p>
    <h3>过往履历/我的优势</h3>
    <div class="txt">
      <textarea
        v-model="txt"
        ref="txt"
        placeholder="2018年9月1日，在xxx公司参与过xx项目，负责过xx部分工作，最终结果xx"
      ></textarea>
    </div>

    <!-- <div class="num">
      <span>{{ num }}</span
      >/140
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      txt: null,
    };
  },
  created() {
    this.txt = localStorage.getItem("advantage");
  },
  mounted() {
    this.$refs.txt.focus();
  },
  // computed: {
  //   num() {
  //     return this.txt == null ? 0 : this.txt.length;
  //   },
  // },
  methods: {
    go() {
      localStorage.setItem("advantage", this.txt);
      this.$router.push("/resume");
    },
  },
  // watch: {
  //   txt(value) {
  //     this.$refs.txt.setAttribute("maxlength", 140);
  //   },
  // },
};
</script>

<style scoped lang='less'>
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.msg .left {
  margin-left: 0.2rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  float: left;
  line-height: 0.88rem;
  margin-left: 0.1rem;
}
.msg .rigth {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f55714;
  float: right;
  line-height: 0.88rem;
  margin-right: 0.1rem;
  margin-right: 0.2rem;
}
h3 {
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  font-size: 0.5rem;

  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
}
.txt {
  box-sizing: border-box;
  height: 7.5rem;
  padding: 0.2rem;
  textarea {
    width: 100%;
    height: 100%;
    font-size: 0.3rem;
    border: 1px solid #979797;
    outline: none;
  }
}
.num {
  position: fixed;
  right: 0.2rem;
  bottom: 0.2rem;
  font-size: 0.45rem;
  span {
    color: #73aa50;
  }
}
</style>